import React from 'react'
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react'
import ProfessionRouting from './ProfessionRouting'
import ProfessionItem from './ProfessionItem'
import { Item, TextField } from '../../types/SitecoreAdapter'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type Profession = {
  href: {
    jss: TextField
  }
  text: {
    jss: TextField
  }
  title: {
    jss: TextField
  }
  escapeText: {
    jss: TextField
  }
  image: {
    alt: string
    src: string
  }
}

type ProfessionRoutingBase = {
  heading: {
    jss: TextField
  }
  subhead: {
    jss: TextField
  }
  escapeText: {
    jss: TextField
  }
  professions: {
    targetItems: Profession[]
  }
}

type ProfessionRoutingProps = Item<{
  data: {
    datasource: ProfessionRoutingBase
  }
}>

const getProfessions = (professions: Profession[]) => {
  return professions.map(({ image, text, href, title }, i) => {
    const link = {
      text: text.jss.value,
      href: href.jss.value,
      title: title.jss.value,
    }
    const imageEl = (
      <img
        src={image.src}
        alt={image.alt}
        width="115"
        height="76"
        loading="lazy"
      />
    )
    return <ProfessionItem key={i} image={imageEl} link={link} />
  })
}

const process = ({
  heading,
  subhead,
  escapeText,
  professions,
}: ProfessionRoutingBase) => {
  return {
    content: {
      heading: <Text field={heading.jss} tag="h2" />,
      subhead: <Text field={subhead.jss} />,
      escapeText: <RichText field={escapeText.jss} />,
    },
    professions: getProfessions(professions.targetItems),
  }
}

const ProfessionRoutingAdapter = ({ fields }: ProfessionRoutingProps) => {
  if (!fields.data.datasource) {
    return null
  }

  const { content, professions } = process(fields.data.datasource)

  return <ProfessionRouting content={content} professions={professions} />
}

export default withErrorBoundary(ProfessionRoutingAdapter, {
  component: 'ProfessionRouting',
})
