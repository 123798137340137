import * as React from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

type SeriesValueProps = {
  heading?: React.ReactNode
  descriptor?: React.ReactNode
  cards: React.ReactNode
}

export default function SeriesValueProp({
  heading,
  descriptor,
  cards,
}: SeriesValueProps) {
  return (
    <div data-test-id={'SeriesValueProp-Wrap'} className={clsx(styles.root, 'Q221SeriesValueProp')}>
      {heading && <h2 className={styles.heading}>{heading}</h2>}
      {descriptor && (
        <div className={clsx(styles.descriptor, 'descriptor')}>
          {descriptor}
        </div>
      )}
      <div className={styles.cards}>{cards}</div>
    </div>
  )
}
