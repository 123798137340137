import React from 'react'

import styles from './styles.module.scss'
import clsx from 'clsx'
import PageTypography from '../../../PageTypography'
import { TP_AdditionalProps } from '../../TrustPilotWidget'

// TP Widget Name: Slider TrustBox Widget
const CarouselWidget = React.forwardRef(
  (props: TP_AdditionalProps, ref: React.Ref<HTMLDivElement>) => {
    return (
      <PageTypography>
        {(pageType: string) => (
          <div
            data-test-id={'carousel-tp-widget'}
            className={clsx(
              'TrustPilotCarouselWidget',
              pageType !== 'home' && 'contain',
              styles[`tpWidget_${pageType}`]
            )}
          >
            <div
              className={clsx(
                styles.widgetContainer,
                props.hideWidgetFooter && styles.hideFooter
              )}
              id="widgetContainer"
            >
              <div
                id={'trustBox'}
                ref={ref}
                className="trustpilot-widget"
                data-locale="en-US"
                data-template-id="54ad5defc6454f065c28af8b"
                data-businessunit-id="545aa5c800006400057b5df7"
                data-style-height="240px"
                data-style-width="100%"
                data-theme="light"
                data-stars="4,5"
                data-review-languages="en"
              ></div>
            </div>
          </div>
        )}
      </PageTypography>
    )
  }
)

export default CarouselWidget
