import { isArray } from 'lodash-es'
import { OptionType } from '../../../../../components/RadioGroup/types'
import { TextField, BaseDataField } from '../../../../../types/SitecoreAdapter'
import {
  PRTPageSettings,
  QuestionType,
  QuestionResponseOptionProps,
  QuestionPageAdapterProps
} from '../types'

export const mapAnsOptions = (options: TextField): OptionType[] => {
  const splittedOptions = options.value.split('\r\n')
  return splittedOptions.map(o => ({ label: o, value: o }))
}

export const mapAns = (a: OptionType | OptionType[]) =>
  isArray(a) ? a.map(a => a.value) : a.value

export const getValue = (field: { value: any }) => (field ? field.value : undefined)

export const mapQuestionResponses = (responses: QuestionPageAdapterProps['quesResponseOptions']) => 
  responses.map(({ fields: resOption, id }) => ({
    label: resOption.optionText.value,
    value: id
  }))

export const mapQuestionsData = (ques: PRTPageSettings['questions']) =>
  ques.map(({ fields: q, id }) => ({
    id, // Question item ID
    icon: { ...getValue(q.questionIcon) },
    question: getValue(q.questionText),
    questionHelpText: getValue(q.questionHelpText),
    ansOptions: mapQuestionResponses(q.quesResponseOptions),
    questionType: getValue(q.questionType), // questionType: "Multiple Response" | "Single Response"
    questionsPageTitle: getValue(q.questionsPageTitle),
    questionsPageDescription: getValue(q.questionsPageDescription),
  }))

export const getAns = (
  ans: OptionType | OptionType[],
  queNo: number,
  questions: QuestionType[]
) => ({ que: questions[queNo - 1].id, ans: mapAns(ans) })
