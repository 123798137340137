const crumbLinkAdapter = (text: string, href: string = '') => ({
  fields: {
    title: { value: text },
    link: { value: { href } },
    linkTitle: { value: text }
  },
})

export const getBreadcrumbsData = (title: string) => {
  return [
    crumbLinkAdapter('Insureon Small Business Blog', '/blog'),
    crumbLinkAdapter(title),
  ]
}