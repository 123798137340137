import clsx from 'clsx'
import React from 'react'
import { LinkWrapperWithChildItems } from '../../LinkWrapper'
import styles from './styles.module.scss'

export interface IProfessionFields {
  image: JSX.Element
  link: {
    text: string
    href: string
    title?: string
  }
}

type LinkAttributes = {
  title?: string
}

type ProfessionItemProps = IProfessionFields & {
  className?: string
}

export default function({
  image,
  link: { text, href, title },
  className = '',
}: ProfessionItemProps) {
  const linkAttrs: LinkAttributes = {}

  if (title) {
    linkAttrs.title = title
  }

  return (
    <LinkWrapperWithChildItems
      href={href}
      {...linkAttrs}
      className={clsx(className, styles.anchor)}
    >
      <div className={styles.image}>{image}</div>
      <div className={styles.slideText}>{text}</div>
    </LinkWrapperWithChildItems>
  )
}
