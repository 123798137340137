import React from 'react'
import HorizontalScroller from '../../HorizontalScroller'
import { IProfessionRoutingProfessions } from '../ProfessionRouting'
import styles from './styles.module.scss'

export default function Mobile({ professions }: IProfessionRoutingProfessions) {
  return (
    <HorizontalScroller className={styles.root}>
      {professions.map((profession, i) =>
        React.createElement(profession.type, {
          key: `profession-${i}`,
          ...profession.props,
          className: styles.slide,
        })
      )}
    </HorizontalScroller>
  )
}
