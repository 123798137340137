import React from 'react'
import clsx from 'clsx'
import {
  Text,
  Image,
  Placeholder,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react'
import {
  TextField,
  BooleanField,
  ImageField,
} from '../../types/SitecoreAdapter'

import CollapsiblePanelBase from './base'
import styles from './styles.module.scss'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type CollapsiblePanelProps = {
  startsOpen: BooleanField
  heading: TextField
  icon?: ImageField
}

type CollapsiblePanelAdapterProps = {
  fields: CollapsiblePanelProps
  rendering: any
}

const CollapsiblePanel = ({
  fields: { startsOpen, heading, icon },
  rendering,
}: CollapsiblePanelAdapterProps) => {
  const getIcon = (icon: ImageField) => {
    return icon.value
  }

  const {
    sitecoreContext: { pageEditing },
  } = useSitecoreContext()

  const hasIcon = icon && getIcon(icon)

  const head = hasIcon ? (
    <div data-test-id="CollapsiblePanel" className={styles.headingWrap}>
      <Image field={icon} className={styles.icon} />
      <Text field={heading} tag="h3" className={styles.headingWithIcon} />
    </div>
  ) : (
    // We want an h3 that looks like an h4, and is left aligned
    <Text field={heading} tag="h3" className={clsx('h4', styles.heading)} />
  )

  const body = <Placeholder name="Accordion-Body" rendering={rendering} />

  const styleOverrideClasses = hasIcon
    ? { body: styles.bodyWithIcon }
    : { body: styles.padBody }

  return pageEditing ? (
    <div>
      {head}
      {body}
    </div>
  ) : (
    <CollapsiblePanelBase
      toggle={head}
      open={startsOpen.value}
      styleOverrides={styleOverrideClasses}
    >
      {body}
    </CollapsiblePanelBase>
  )
}

export default withErrorBoundary(CollapsiblePanel, {
  component: 'CollapsiblePanel',
})
