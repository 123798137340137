import React from 'react'
import Media from '../Media'
import Desktop from './Desktop'
import Mobile from './Mobile'
import ContentWrapper from './ContentWrapper'

export interface IProfessionRoutingContent {
  content: {
    heading?: JSX.Element
    subhead?: JSX.Element
    escapeText?: JSX.Element
  }
}

export interface IProfessionRoutingProfessions {
  professions: JSX.Element[]
}

export type ProfessionRoutingProps = {
  renderStatic?: boolean
} & IProfessionRoutingContent &
  IProfessionRoutingProfessions

export default function ProfessionRouting({
  content,
  professions,
}: ProfessionRoutingProps) {
  return (
    <ContentWrapper content={content}>
      {getChildren(professions)}
    </ContentWrapper>
  )
}

function getChildren(
  professions: JSX.Element[]
): JSX.Element {
  if (professions.length < 6) {
    return <Mobile professions={professions} />
  }

  return (
    <Media
      mobile={() => <Mobile professions={professions} />}
      tablet={() => <Desktop professions={professions} />}
    />
  )
}
