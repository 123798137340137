import React from 'react'
import { IProfessionRoutingContent } from '../ProfessionRouting'
import CTALinkIntercept from '../../../quotes/CTALinkIntercept'
import clsx from 'clsx'
import styles from './styles.module.scss'
import PageTypography from '../../PageTypography'

type WrapperProps = {
  children: JSX.Element
} & IProfessionRoutingContent

export default function({
  children,
  content: { heading, subhead, escapeText },
}: WrapperProps) {
  return (
    <PageTypography>
      {(pageType: string) => (
        <div
          className={clsx(
            'block contain ProfessionRouting',
            styles.root,
            pageType === 'blog' && styles.blogPadding
          )}
        >
          {getHeading(heading, subhead)}
          {getChildren(heading, subhead, children)}
          {getEscapeText(escapeText)}
        </div>
      )}
    </PageTypography>
  )
}

function getChildren(
  heading: JSX.Element | undefined,
  subhead: JSX.Element | undefined,
  children: JSX.Element
) {
  const carouselClasses = clsx(
    !heading && !subhead && styles.carouselOnly,
    styles.carousel
  )

  return <div className={carouselClasses}>{children}</div>
}

function getHeading(
  heading: JSX.Element | undefined,
  subhead: JSX.Element | undefined
) {
  if (!heading) return null
  return (
    <>
      <div className={styles.heading}>{heading}</div>
      {subhead && <div className={styles.subhead}>{subhead}</div>}
    </>
  )
}

function getEscapeText(escapeText: JSX.Element | undefined) {
  if (!escapeText) return null
  return (
    <div className={styles.escapeText}>
      <CTALinkIntercept preSelectDisabled={true}>{escapeText}</CTALinkIntercept>
    </div>
  )
}
