import React from 'react'
import loadable from '@loadable/component'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import ExploreOnInsureon, {
  LinkFields,
} from '../../../components/Explore-On-Insureon'
import { Item, RouteFields, TextField } from '../../../types/SitecoreAdapter'
import { CTABannerFields, SidebarCTAProps } from './SidebarCTA'
import { getRelatedPosts, RelatedPostsFields } from './getRelatedPosts'
import RelatedPosts from './RelatedPosts'

const SocialSharingSection = loadable(() => import('./SocialSharingSection'))
const SidebarCTA = loadable(() => import('./SidebarCTA'))

type AsideRouteFields = {
  relatedPosts: RelatedPostsFields
  onInsureonTitle: TextField
  onInsureon: LinkFields[]
} & SidebarCTAProps

type AsideAdapterProps = {
  showSideBarCTA: boolean
} & RouteFields<AsideRouteFields>

const AsideAdapter = ({
  showSideBarCTA,
  sitecoreContext: {
    route: {
      fields: { relatedPosts, ctaBanner, onInsureonTitle, onInsureon },
    },
  },
}: AsideAdapterProps) => {
  return (
    <>
      <RelatedPosts relatedPosts={getRelatedPosts(relatedPosts)} />
      {showSideBarCTA && getSidebar(ctaBanner)}
      <ExploreOnInsureon title={onInsureonTitle} linkItems={onInsureon} />
      <SocialSharingSection />
    </>
  )
}

export default withSitecoreContext()(AsideAdapter)

function getSidebar(ctaBanner: CTABannerFields) {
  return <SidebarCTA ctaBanner={ctaBanner} />
}
