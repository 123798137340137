import React, { ReactNode } from 'react'
import BackgroundImage from './BackgroundImage'
import styles from './styles.module.scss'
import clsx from 'clsx'

type HeroProps = {
  backgroundImage: ReactNode
  heading: ReactNode
  descriptor: ReactNode
  isDark?: boolean
  children: ReactNode
}

const HomepageHero = ({
  backgroundImage,
  heading,
  descriptor,
  isDark,
  children,
}: HeroProps) => {
  return (
    <div
      className={styles.heroWrapper}
      data-test-context="Hero"
      data-embed="Homepage-Hero"
    >
      <div className={styles.body}>
        <h1 className={clsx(styles.title, !isDark && styles.dark)}>
          {heading}
        </h1>
        {/* empty div is to allow a container with flex-basis 100%, while still limiting content width */}
        <div>
          <div className={clsx(styles.descriptor, !isDark && styles.dark)}>{descriptor}</div>
        </div>
        {children}
      </div>
      <BackgroundImage>{backgroundImage}</BackgroundImage>
    </div>
  )
}

export default HomepageHero
