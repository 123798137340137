import * as React from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import { ImageField } from '../../types/SitecoreAdapter'
import { addQueryParamsToUrl } from "../../util/imageUrlUtil"

type PropCard = {
  label: React.ReactNode
  imageProps: ImageField
}

export default function PropCard({ label, imageProps }: PropCard) {
  return (
    <div className={styles.card}>
      <div className={styles.cardImage}>
        <img
          src={addQueryParamsToUrl(imageProps.value.src, `mh=80`)}
          title={imageProps.value.title}
          alt={imageProps.value.alt}
          height={imageProps.value.height}
          width={imageProps.value.width}
        />
      </div>
      <div className={clsx(styles.cardLabel, 'h4')}>{label}</div>
    </div>
  )
}
