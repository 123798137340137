import React from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import SeriesValueProp from './SeriesValueProp'
import PropCard from './PropCard'
import { TextField, ImageField } from '../../types/SitecoreAdapter'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type SeriesProps = {
  fields: {
    heading: TextField
    descriptor: TextField
    leftImage: ImageField
    leftHeading: TextField
    middleImage: ImageField
    middleHeading: TextField
    rightImage: ImageField
    rightHeading: TextField
  }
}

function SeriesValuePropAdapter({
  fields: {
    heading,
    descriptor,
    leftImage,
    leftHeading,
    middleImage,
    middleHeading,
    rightImage,
    rightHeading,
  },
}: SeriesProps) {
  const cards = [
    <PropCard
      imageProps={leftImage}
      label={<Text field={leftHeading} />}
      key="left"
    />,
    <PropCard
      imageProps={middleImage}
      label={<Text field={middleHeading} />}
      key="middle"
    />,
    <PropCard
      imageProps={rightImage}
      label={<Text field={rightHeading} />}
      key="right"
    />,
  ]
  return (
    <SeriesValueProp
      heading={<Text field={heading} />}
      descriptor={<Text field={descriptor} />}
      cards={cards}
    />
  )
}

export default withErrorBoundary(SeriesValuePropAdapter, {
  component: 'Q221-Series-Value-Prop',
})
