import React, { Suspense, useContext } from 'react'

import TestMetadata from '../../components/Test-Metadata'
import HomePageHero, { HomePageHeroProps } from '../../components/Homepage-Hero'
import Banner from '../../components/Banner'
import { Placeholder, RouteData } from '@sitecore-jss/sitecore-jss-react'

import PartnerLogo from './PartnerLogo'
import styles from './styles.module.scss'
import LinkWrapper from '../../components/LinkWrapper'
import { EnvironmentContext } from '../../context/Environment/EnvironmentContext'
import HeroAppStartSwitch from '../../quotes/HeroAppStartSwitch'
import { get } from 'lodash-es'

type PartnerProps = {
  route: {
    fields: {
      hero: HomePageHeroProps
    }
  } & RouteData
}

const PartnerPage = ({ route }: PartnerProps) => {
  const { hero } = route.fields
  const environmentContext = useContext(EnvironmentContext)

  return (
    <>
      <PartnerNav loginHref={environmentContext.endpoints.portal} />
      <Banner />
      <Suspense>
        <div className="copy Partner">
          <TestMetadata pageType="Partner" appStartType="Typeahead" />

          <HomePageHero {...hero}>
            <HeroAppStartSwitch
              darkBg={get(hero, 'fields.isDark.value', false)}
              appStartProps={{ rootClass: styles.ctaRoot }}
            />
          </HomePageHero>

          <Placeholder name="Hero-Placeholder" rendering={route} />

          <Placeholder name="Partner-Placeholder" rendering={route} />
        </div>
      </Suspense>
    </>
  )
}

function PartnerNav({ loginHref }: { loginHref: string }) {
  return (
    <div className={styles.nav}>
      <PartnerLogo className={styles.logo} />
      <LinkWrapper href={loginHref} className={styles.login} text={'Log in'} />
    </div>
  )
}

export default PartnerPage
