import React, { Suspense } from 'react'
import Media from '../../components/Media'
import ArticleBody from './ArticleBody'
import TagTopics from './TagTopics'
import BlogBottomCTA from '../../page-components/BlogBottomCTA'
import Mobile from './Mobile'
import Desktop from './Desktop'
import { isIE11 } from '../../util/IE11'

const cta = <BlogBottomCTA />

const getMobile = () => (
  <Mobile article={<ArticleBody />} tagTopics={<TagTopics />} cta={cta} />
)

const getDesktop = () => (
  <Desktop
    article={<ArticleBody />}
    showSideBarCTA={!isIE11}
    tagTopics={<TagTopics />}
    cta={cta}
  />
)

function BlogPost() {
  if (isIE11) {
    return getMobile()
  }

  return (
    <Suspense>
      <Media mobile={getMobile} desktop={getDesktop} />
    </Suspense>
  )
}

export default BlogPost
