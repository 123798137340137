import React from 'react'

import styles from './styles.module.scss'
import clsx from 'clsx'
import PageTypography from '../../../PageTypography'
import { TP_AdditionalProps } from '../../TrustPilotWidget'

const GridWidget = React.forwardRef(
  (props: TP_AdditionalProps, ref: React.Ref<HTMLDivElement>) => {
    return (
      <PageTypography>
        {(pageType: string) => (
          <div
            className={clsx(
              'TrustPilotGridWidget',
              pageType !== 'home' && 'contain',
              styles[`tpWidget_${pageType}`]
            )}
            data-test-id={'grid-tp-widget'}
          >
            <div className={clsx(styles.widgetContainer)} id="widgetContainer">
              <div
                id={'trustBox'}
                ref={ref}
                data-locale="en-US"
                data-template-id="539adbd6dec7e10e686debee"
                data-businessunit-id="545aa5c800006400057b5df7"
                data-style-width="100%"
                data-theme="light"
                data-stars="3,4,5"
                data-review-languages="en"
              ></div>
            </div>
          </div>
        )}
      </PageTypography>
    )
  }
)

export default GridWidget
