import React from 'react'
import { Image, Text } from '@sitecore-jss/sitecore-jss-react'
import clsx from 'clsx'
import * as D from '../../types/SitecoreAdapter'

import styles from './styles.module.scss'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type SeriesProps = {
  fields: {
    heading: D.TextField
    descriptor: D.TextField
    leftImage: D.ImageField
    leftHeading: D.TextField
    middleImage: D.ImageField
    middleHeading: D.TextField
    rightImage: D.ImageField
    rightHeading: D.TextField
  }
}

const SeriesValueProp = ({
  fields: {
    heading,
    descriptor,
    leftImage,
    leftHeading,
    middleImage,
    middleHeading,
    rightImage,
    rightHeading,
  },
}: SeriesProps) => (
  <div data-test-id={'SeriesValueProp'} className="block SeriesValueProp">
    <Text
      field={heading}
      tag="h2"
      className={clsx(styles.heading, 'contain')}
    />
    <Text field={descriptor} className={clsx(styles.descriptor)} />
    <div className={clsx(styles.cardContainer, 'contain')}>
      <ValueCard image={leftImage} heading={leftHeading} />
      <ValueCard image={middleImage} heading={middleHeading} />
      <ValueCard image={rightImage} heading={rightHeading} />
    </div>
  </div>
)

const ValueCard = ({
  image,
  heading,
}: {
  image: D.ImageField
  heading: D.TextField
}) => (
  <div className={styles.card}>
    <Image field={image} />
    <Text field={heading} tag="h3" className={styles.imageHeading} />
  </div>
)

export default withErrorBoundary(SeriesValueProp, {
  component: 'Series-Value-Prop',
})
